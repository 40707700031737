<template>
  <div>
    <div class="form-group">
                    <div class="input-icon input-icon-right">
                      <input
                        type="text"
                        class="form-control"
                        @input="search"
                        v-model="filter"
                        placeholder="Search by name or phone"
                      />
                      <span @click="search"
                        ><i class="flaticon2-search-1 icon-md"></i
                      ></span>
                    </div>
                  </div>
    <b-table
      :items="table.items"
      :fields="$t('clan')=='en'?table.fields:tableAR.fields"
      :responsive="true"
      :current-page="currentPage"
      :per-page="perPage"
      stacked="md"
      show-empty
    >

    <template #empty>
              <div v-if="isBusy" class="text-center ">
                <b-spinner
                  variant="primary"
                  label="Spinning"
                  class="spinnerClass"
                ></b-spinner>
              </div>
              <p
                v-else

                class="text-center muted mb-0 mt-2"
                style="font-size: 13px"
              >
                {{ $t('There are no records to show') }}
              </p>
            </template>

      <template #cell(actions)="row">
        <div class="">
          <button @click.prevent="viewDriver(row.item.id)" class="">
            <i class="far fas fa-eye"></i>
            {{$t('view')}}
          </button>
        </div>
      </template>

      <template #row-details="row">
        <b-card>
          <ul>
            <li v-for="(value, key) in row.item" :key="key">
              {{ key }}: {{ value }}
            </li>
          </ul>
        </b-card>
      </template>
    </b-table>

    <!--<b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="left"
      size="lg"
      class="my-0"
      @click.native="getData"
    ></b-pagination> -->

    <ul class="pagination" :style="$t('clan')=='ar'?'float:right':''" dir="ltr">
    <li class="page-item "><a class="page-link"  @click="prev">{{$t('Prev')}}</a></li>
    <li class="page-item"><a class="page-link"  @click="next">{{$t('Next')}}</a></li>
  </ul>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "DriversTable",
  async mounted() {
    this.isBusy = true
    await this.$store.dispatch("drivers/loadAll").then((res) => {
      this.totalRows = res.data.total;
      this.lastPage = res.data.last_page
    });
    this.isBusy = false
  },
  data: function () {
    return {
      isBusy:'',
      filter:'',
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      firstPage:1,
      lastPage:''
    };
  },
  computed: {
    ...mapGetters({ table: "drivers/table" }),
    ...mapGetters({ tableAR: "drivers/tableAR" }),
  },

  methods: {
    search(){
      this.$store.dispatch("drivers/search", this.filter).then((res)=>{
        //console.log(res)
        this.currentPage = res.data.current_page
        this.totalRows = res.data.total;
      this.lastPage = res.data.last_page
      });
    },
    next(){
      if(this.currentPage < this.lastPage){
        this.currentPage = this.currentPage + 1
      }
      
      this.getData()

    },
    prev(){
      if(this.currentPage > this.firstPage){
        this.currentPage = this.currentPage - 1
      }
      
      this.getData()

    },
    async getData() {
      this.isBusy = true
      await this.$store.dispatch("drivers/loadPage", this.currentPage);
      this.isBusy = false
    },
    viewDriver(id) {
      this.$router.push({ name: "ViewDriver", params: { id: id } });
    },
  },
};
</script>

<style scoped>
.add {
  margin-top: 20px;
}
.icon-md:hover {
  color: #4f2c85 !important;
  cursor: pointer;
}
</style>
